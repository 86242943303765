export const idlFactory = ({ IDL }) => {
  const Delegation = IDL.Record({
    'signature' : IDL.Vec(IDL.Nat8),
    'delegation' : IDL.Record({
      'pubkey' : IDL.Vec(IDL.Nat8),
      'targets' : IDL.Opt(IDL.Vec(IDL.Principal)),
      'expiration' : IDL.Int,
    }),
  });
  const AuthResponse = IDL.Record({
    'kind' : IDL.Text,
    'delegations' : IDL.Vec(Delegation),
    'authnMethod' : IDL.Text,
    'userPublicKey' : IDL.Vec(IDL.Nat8),
  });
  const Result_1 = IDL.Variant({
    'ok' : IDL.Record({
      'auth' : AuthResponse,
      'cost' : IDL.Float64,
      'usage' : IDL.Float64,
      'perf0' : IDL.Nat64,
      'perf1' : IDL.Nat64,
    }),
    'err' : IDL.Text,
  });
  const Result = IDL.Variant({
    'ok' : IDL.Record({
      'cost' : IDL.Float64,
      'pubKey' : IDL.Vec(IDL.Nat8),
      'usage' : IDL.Float64,
      'perf0' : IDL.Nat64,
      'perf1' : IDL.Nat64,
    }),
    'err' : IDL.Text,
  });
  return IDL.Service({
    'getDelegations' : IDL.Func(
        [IDL.Text, IDL.Vec(IDL.Nat8), IDL.Nat],
        [Result_1],
        ['query'],
      ),
    'getPrincipal' : IDL.Func([], [IDL.Text], ['query']),
    'prepareDelegation' : IDL.Func([IDL.Text, IDL.Nat32], [Result], []),
  });
};
export const init = ({ IDL }) => { return []; };
